<template>
	<div class="title-box"></div>
	<div class="catalyst">
		<div class="get-started-box">
			<div class="title">Get started</div>
			<img class="e-wallet" src="../assets/img/dao/e-wallet.svg" alt="" />
			<div class="desc">
				You can use the <span>Metamask</span> extension or a hardware
				wallet like <span>Ledger Nano S</span>
			</div>
			<div class="btn-connect" @click="login">Connect</div>
		</div>
	</div>
	<Foot :theme="'white'" />
</template>

<script>
import Foot from '@/components/FOOT.vue'

export default {
	name: 'CatalystPage',
	components: {
		Foot,
	},
	data() {},
	created() {
		const loginBy = localStorage.getItem('loginBy')
		if (loginBy) {
			if (this.$route.query.nextName) {
				this.$router.push({
					name: this.$route.query.nextName,
					params: {
						category: this.$route.query.nextParams,
					},
				})
			} else {
				this.$router.push({
					name: 'Home',
				})
			}
		}
	},
	methods: {
		login() {
			this.isShowNavbar = false
			this.$store.dispatch('showLoginPopup', true)
		},
	},
	mounted() {
		document.querySelector('.gnb-box-bg').scrollIntoView()
	},
}
</script>

<style lang="scss" scoped>
.title-box {
	@include FLEXV(flex-start, center);
	width: 100%;
	height: gREm(204);
	background-image: url('../assets/img/dao/header-bg.png');
	background-repeat: no-repeat;
	background-size: cover;
}

.catalyst {
	font-family: 'Montserrat';
	background: #e5e5e5;
	display: flex;
	align-items: center;
	min-height: calc(100vh - 100px - 281px);
	padding-top: 20px;
	padding-bottom: 20px;
	.get-started-box {
		width: 100%;
		max-width: 480px;
		height: 100%;
		margin: 0 auto;
		padding: 40px 36px 60px;
		background: #ffffff;
		border-radius: 8px;
		display: flex;
		flex-direction: column;
		align-items: center;
		& .title {
			font-weight: 700;
			font-size: 36px;
			line-height: 56px;
			color: #201360;
			text-align: center;
		}
		& .e-wallet {
			width: 100%;
			height: 100%;
			max-width: 138px;
			max-height: 138px;
			margin-top: gREm(30px);
			margin-bottom: gREm(40px);
		}
		& .desc {
			font-weight: 400;
			line-height: 21px;
			font-size: 14px;
			color: #000000;
			text-align: center;
			& span {
				color: #6c38ef;
				font-weight: 500;
			}
		}
		& .btn-connect {
			padding: gREm(8px) gREm(24px);
			background: #6c38ef;
			border-radius: 4px;
			margin-top: gREm(40px);
			cursor: pointer;
			color: #ffffff;
		}
	}
}

@include media-max($media_large) {
	// 1200
}

@include media-max($media_medium) {
	// 1024
}

@include media-max($media_small) {
	// 720
	.catalyst {
		.get-started-box {
			width: 90%;
			height: auto;
		}
	}
}
</style>
